export default {
  // Temp fonts
  fonts: {
    title: "Space Grotesk, sans-serif",
    main: "Space Grotesk, sans-serif"
  },
  // Colors for layout
  colors: {
    primary1: "linear-gradient(135deg, #854CE6 0%, #D16BA5 100%)",
    background1: "linear-gradient(135deg, #333A45 0%, #2B3745 100%)", // Brighter background
    button: "linear-gradient(135deg, #854CE6 0%, #D16BA5 100%)",
    background2: "linear-gradient(135deg, #29313C 0%, #1A2830 100%)",
    text: "linear-gradient(135deg, #C8CFD8 0%, #A0AABF 100%)",
    text1: "linear-gradient(135deg, #F2F5F7 0%, #E0E5EC 100%)",
    text2: "linear-gradient(135deg, #626970 0%, #4A5158 100%)",
    text3: "linear-gradient(135deg, #575C66 0%, #3E434D 100%)",
    footerBackground: "linear-gradient(135deg, #00012B 0%, #00001A 100%)"
  },
  // Breakpoints for responsive design
  breakpoints: {
    sm: 'screen and (max-width: 640px)',
    md: 'screen and (max-width: 768px)',
    lg: 'screen and (max-width: 1024px)',
    xl: 'screen and (max-width: 1280px)'
    // Add other breakpoints if needed
  }
}